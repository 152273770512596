import { NavLink as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Link, Stack } from '@mui/material';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(5),
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
        opacity: 0.48,
        textDecoration: 'none'
    }
}));

// ----------------------------------------------------------------------

function MenuDesktopItem({ item, isHome, isOffset }) {
    const { title, path } = item;

    return (
        <LinkStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                ...(isHome && { color: 'common.white' }),
                ...(isOffset && { color: 'text.primary' }),
                textDecoration: 'none',
                '&.active': {
                    color: 'primary.main'
                }
            }}
        >
            {title}
        </LinkStyle>
    );
}

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
    return (
        <Stack direction="row">
            {navConfig.map((link) => (
                <MenuDesktopItem
                    key={link.title}
                    item={link}
                    isOffset={isOffset}
                    isHome={isHome}
                />
            ))}
        </Stack>
    );
}
