// material
import { styled } from '@mui/material/styles';
import { Box, Grid, Container, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StoreIcon from '@mui/icons-material/Store';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Logo from '../../components/Logo';

const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    // background: 'rgb(22,28,36)',
    color: theme.palette.common.white,
    background: 'linear-gradient(90deg, rgba(22,28,36,1) 0%, rgba(31,37,45,1) 25%, rgba(78,85,93,1) 50%, rgba(99,105,114,1) 75%, rgba(100,106,115,1) 100%)',
    position: 'relative',
    // [theme.breakpoints.up('md')]: {
    //     paddingBottom: theme.spacing(15)
    // }
}));


const MainFooter = () => {
    return (
        <RootStyle>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>

                        <Logo color='white' sx={{ maxWidth: 300, width: '100%', height: 'auto', mb: 2 }} />


                        <MenuItem>
                            <ListItemIcon>
                                <EmailOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>imagenthaspa@gmail.com</ListItemText>
                        </MenuItem>

                        <MenuItem>
                            <ListItemIcon>
                                <PhoneInTalkIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>22 814 2417</ListItemText>
                        </MenuItem>


                        <MenuItem>
                            <ListItemIcon>
                                <WhatsAppIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>+ 569 8990 6557</ListItemText>
                        </MenuItem>

                        <MenuItem>
                            <ListItemIcon>
                                <StoreIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Manuel Castillo 106L-B, Peñaflor</ListItemText>
                        </MenuItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box width={'100%'}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1586.4721725755103!2d-70.8571508945954!3d-33.60993350866691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662e0d4ebff43e9%3A0x5921d639436c4c3a!2simagentha%20camaras!5e0!3m2!1sen!2scl!4v1706665360510!5m2!1sen!2scl"
                                width="100%"
                                height="450"
                                title="camera cctv"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}

export default MainFooter;