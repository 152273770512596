import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { List, Link, Drawer, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    height: ITEM_SIZE,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(PADDING),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary
}));

// ----------------------------------------------------------------------

function MenuMobileItem({ item }) {
    const { title, path, icon } = item;

    return (
        <ListItemStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                '&.active': {
                    color: 'primary.main',
                    fontWeight: 'fontWeightMedium',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
                }
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={title} />
        </ListItemStyle>
    );
}

export default function MenuMobile({ isOffset, isHome, navConfig }) {
    const { pathname } = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (drawerOpen) {
            handleDrawerClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <MIconButton
                onClick={handleDrawerOpen}
                sx={{
                    ml: 1,
                    ...(isHome && { color: 'common.grey' }),
                    ...(isOffset && { color: 'text.primary' })
                }}
            >
                <Icon icon={menu2Fill} />
            </MIconButton>

            <Drawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                PaperProps={{ sx: { pb: 5, width: 260 } }}
            >
                <Scrollbar>
                    <Link component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                        <Logo sx={{ mx: PADDING, my: 3 }} />
                    </Link>

                    <List disablePadding>
                        {navConfig.map((link) => (
                            <MenuMobileItem key={link.title} item={link} />
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
}
