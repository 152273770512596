import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
        <Suspense
            fallback={
                <LoadingScreen
                    sx={{
                        ...(!isDashboard && {
                            top: 0,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        })
                    }}
                />
            }
        >
            <Component {...props} />
        </Suspense>
    );
};


export default function Router() {
    return useRoutes([
        // Default routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                // { path: 'coming-soon', element: <ComingSoon /> },
                // { path: 'maintenance', element: <Maintenance /> },
                // { path: 'pricing', element: <Pricing /> },
                // { path: 'payment', element: <Payment /> },
                // { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> }
            ]
        },
        // Landing routes
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { path: '', element: <LandingPage /> },
            ]
        },
        // Default 404
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}

const LandingPage = Loadable(lazy(() => import('../pages/landingPage/LandingPage')));
const NotFound = Loadable(lazy(() => import('../pages/default/Page404')));