import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
        height: APP_BAR_DESKTOP
    }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

const MainNavbar = () => {
    const isOffset = useOffSetTop(100);
    const { pathname } = useLocation();
    const isHome = pathname === '/';

    return <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
        <ToolbarStyle
            disableGutters
            sx={{
                ...(isOffset && {
                    bgcolor: 'background.default',
                    height: { md: APP_BAR_DESKTOP - 16 }
                })
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <RouterLink to="/">
                    <Logo color={isOffset ? 'default' : 'white'} />
                </RouterLink>
                <Box sx={{ flexGrow: 1 }} />

                {/* <MHidden width="mdDown">
                    <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
                </MHidden> */}

                <Button variant="contained" color='secondary' target="_blank" href="https://wa.me/+56989906557" sx={{ backgroundColor: "#2DB641", ":hover": { backgroundColor: "#258e34" } }} startIcon={<Icon icon='ic:baseline-whatsapp' width={20} height={20} />}>
                    ¡Hablemos!
                </Button>

                {/* <MHidden width="mdUp">
                    <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
                </MHidden> */}
            </Container>
        </ToolbarStyle>

        {isOffset && <ToolbarShadowStyle />}
    </AppBar>
}

export default MainNavbar;