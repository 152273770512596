import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeConfig>
          <GlobalStyles />
          <Router />
        </ThemeConfig>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
