import { Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Icon } from '@iconify/react';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {

    return (
        <>
            <MainNavbar />
            <div>
                <Outlet />
            </div>

            <MainFooter />

            <Box
                sx={{
                    py: 3,
                    textAlign: 'center',
                    position: 'relative',
                    bgcolor: 'background.default'
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="caption" component="p">
                        Copyright © 2024, Derechos reservados
                        <br /> made by &nbsp;
                        <Link href="https://www.linkedin.com/in/victoria-rodriguez-zuniga/" color='primary.darker'>
                            <Chip sx={{ height: 24, '& .MuiChip-label': { marginBottom: '3px' } }} icon={<Icon icon='mdi:linkedin' />} label={<Typography variant="caption">vxr</Typography>} />
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </>
    );
}
