// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const defaultLogo = '/static/logos/logo-horizontal.png';
const whiteLogo = '/static/logos/logo-horizontal-blanco.png';
export default function Logo({ sx, color = 'default' }) {

    const colorURL = color === 'white' ? whiteLogo : defaultLogo;
    return (
        <Box sx={{ width: 151, height: 47, ...sx }}>
            <Box component='img' src={colorURL} width='100%' height='auto' />
        </Box>
    );
}
