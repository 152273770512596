// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

const IS_LIGHT = true;
const ThemeConfig = ({ children }) => {
    const themeOptions = {
        palette: IS_LIGHT ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
        // shape,
        typography,
        // breakpoints,
        shadows: IS_LIGHT ? shadows.light : shadows.dark,
        customShadows: IS_LIGHT ? customShadows.light : customShadows.dark
    };

    const theme = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeConfig;